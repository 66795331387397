import { Asset } from '@gain/rpc/app-model'
import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import Card from '../../../common/card/card'
import RequestProfileDialog from '../../../features/request-profile'

const StyledCard = styled(Card)(({ theme }) => ({
  flex: 'unset',
  display: 'flex',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey['100'],
  boxShadow: 'none',
  flexDirection: 'row',
  padding: theme.spacing(1.5, 3),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
}))

const StyledCardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  flexWrap: 'wrap',
}))

const StyledCardButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
})

interface AssetAutomatedRequestDetailedProps {
  asset: Asset
}

export default function AssetAutomatedRequestDetailed({
  asset,
}: AssetAutomatedRequestDetailedProps) {
  const [open, showRequestProfileDialog, hideRequestProfileDialog] = useDialogState()

  return (
    <StyledCard>
      <StyledCardContent>
        <Typography variant={'subtitle2'}>
          Want to know more about this company?
        </Typography>
        <Typography
          color={'text.secondary'}
          variant={'body2'}>
          This AI-generated profile is built from reliable sources. Contact us if you need in-depth analyst research.
        </Typography>
      </StyledCardContent>
      <StyledCardButton>
        <Button
          color={'primary'}
          onClick={showRequestProfileDialog}
          size={'small'}
          variant={'outlined'}>
          Request analyst research
        </Button>
      </StyledCardButton>

      <RequestProfileDialog
        companyName={asset.generalInfo?.name}
        companyWebsite={asset.generalInfo?.url || ''}
        onClose={hideRequestProfileDialog}
        open={open}
      />
    </StyledCard>
  )
}
