import * as H from 'history'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ProfileTab } from './profile-info-and-tabs-model'

interface MinimalTab {
  value: string
  path: string
}

/**
 * Remove any query params, we don't need that when matching
 */
function stripQueryParams(path: string) {
  return path.split('?').shift() ?? path
}

export function useActiveTab(tabs: ProfileTab[]): string | undefined {
  const location = useLocation()

  return useMemo(() => {
    if (tabs.length === 0) {
      return undefined
    }

    // Flattens the tabs with possible subTabs so we can mark the right tab active
    const flattenedTabs = tabs.reduce((flatTabs, tab) => {
      if (tab.subTabs && tab.subTabs.length > 0) {
        tab.subTabs.forEach((subTab) =>
          flatTabs.push({
            value: tab.value,
            path: stripQueryParams(subTab.path),
          })
        )
      } else {
        flatTabs.push({
          value: tab.value,
          path: stripQueryParams(tab.path),
        })
      }

      return flatTabs
    }, [] as MinimalTab[])

    return getActiveTab(location, flattenedTabs)
  }, [location, tabs])
}

export function useActiveSubTab(tabs: MinimalTab[]): string | undefined {
  const location = useLocation()

  return useMemo(() => getActiveTab(location, tabs), [location, tabs])
}

function getActiveTab(location: H.Location<unknown>, tabs: MinimalTab[]): string | undefined {
  if (tabs.length === 0) {
    return undefined
  }

  return tabs.find(({ path }) => location.pathname.startsWith(path))?.value
}
