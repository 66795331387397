import { ChevronDownIcon } from '@gain/components/icons'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { TabOwnProps } from '@mui/material/Tab'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { ProfileTab } from './profile-info-and-tabs-model'
import { useActiveSubTab } from './profile-tab-bar-hooks'

interface ProfileTabBarTabProps extends TabOwnProps {
  id?: string
  className?: string
  replace?: boolean
  tab: ProfileTab
}

/**
 * Represents a tab within a profile tab bar, which may contain sub-tabs or directly navigate to a path.
 */
export default function ProfileTabBarTab({ className, id, replace, tab }: ProfileTabBarTabProps) {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(null)
  const history = useHistory()
  const activeSubTab = useActiveSubTab(tab?.subTabs ?? [])

  const visibleSubTabs = tab?.subTabs?.filter((subTab) => !subTab.hidden)

  if (visibleSubTabs && visibleSubTabs.length > 0) {
    return (
      <>
        <a
          className={className}
          id={id}
          onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}>
          {tab.label}
          &nbsp;
          <ChevronDownIcon />
        </a>

        <Menu
          anchorEl={moreMenuAnchorEl}
          onClose={() => setMoreMenuAnchorEl(null)}
          open={!!moreMenuAnchorEl}>
          {visibleSubTabs.map((subTab) => (
            <MenuItem
              key={subTab.path}
              onClick={() => {
                history[replace ? 'replace' : 'push'](subTab.path)
                setMoreMenuAnchorEl(null)
              }}
              selected={subTab.value === activeSubTab}>
              {subTab.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  return (
    <Link
      className={className}
      data-testid={tab.path}
      id={id}
      replace={replace}
      to={tab.path}>
      {tab.label}
    </Link>
  )
}
